import { AxiosError, AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { createEffect } from 'effector';
import { isEmpty } from 'lodash';
import { Router } from 'next/router';
import { i18n } from 'next-i18next';
import config from 'config/config';
import api from 'services/api';
import { fetchHallLayoutsFx } from 'shared/api/reference/hall-layouts';
import { fetchHallsFx } from 'shared/api/reference/halls';
import {
  IBasicEvent,
  ICreateDatesFx,
  ICreateParserDateFx,
  IEditDateFx,
  IEditEventFx,
  IEventParsers,
  IEventsList,
  IFetchEventLabelsFxResponse,
  IManager,
  IParserFilterParams,
  IUpdateDateStepFx,
  IUpdateEventStepFx,
  IWidgetData,
} from './types';

dayjs.extend(utc);
dayjs.extend(timezone);

const route = `${config.COMMON_API_URL}/admin/v1/events`;

export const basicEventSources = {
  root: route,
  detail: (id: number) => `${route}/${id}`,
  childDetail: (id: number) => `${route}?date_id_in=${id}`,
  announcements: (id: number) => `${config.TICKETS_API_URL}/admin/v1/events/${id}/announcements`,
};

export const eventRestrictions = `${config.COMMON_API_URL}/admin/v1/restrictions`;
export const dateStatus = `${config.COMMON_API_URL}/admin/v1/date_statuses`;

export const fetchEventPermission = createEffect<number, { update_allowed: boolean }, AxiosError>(
  async (id) => {
    const response = await api.get(`event/${id}/get-permission`);

    return response.data;
  },
);

export const fetchEventsFx = createEffect<Router['query'], IEventsList, AxiosError>(async (query) => {
  const { location, ...rest } = query;
  const response = await api.get(route, {
    params: {
      ...rest,
      ...(query && { language_code: i18n?.language }),
      ...(location && { location_id_in: location }),
    },
  });

  return response.data;
});

export const fetchEventFx = createEffect<
  { id: number; config?: AxiosRequestConfig },
  IBasicEvent,
  AxiosError
>(async ({ id, config }) => {
  const response = await api.get(`${route}/${id}`, config);

  return response.data;
});

export const fetchUserFx = createEffect<
  number,
  { count: number; last_page: number; limit: number; page: number; results: IManager[] },
  AxiosError
>(async (id) => {
  const response = await api.get(`${config.API_URL}auth/internal/users`, { params: { id__in: id } });

  return response.data.data;
});

export const createEventFx = createEffect(
  async (data: { title: string; category_id: number; manager_id: number | null; restriction_id: number }) => {
    const response = await api.post(route, data);

    return response.data;
  },
);

export const editEventFx = createEffect<IEditEventFx, void, AxiosError>(({ id, data }) => {
  api.patch(`${route}/${id}`, data);
});

export const deleteDocumentFx = createEffect<{ eventId: number; documentId: number }, void, AxiosError>(
  ({ eventId, documentId }) => {
    api.delete(`${config.COMMON_API_URL}/admin/v1/events/${eventId}/documents/${documentId}`);
  },
);

export const createDocumentFx = createEffect<{ eventId: number; documentId: number }, void, AxiosError>(
  ({ eventId, documentId }) => {
    api.post(`${config.COMMON_API_URL}/admin/v1/events/${eventId}/documents`, {
      document_id: documentId,
    });
  },
);

export const updateEventStepFx = createEffect<
  IUpdateEventStepFx,
  { eventData: { id: number; title: string } },
  AxiosError
>(async ({ eventData, dataForUpdate, document }) => {
  if (eventData) {
    await editEventFx({ id: eventData.id, data: dataForUpdate });
  } else {
    eventData = await createEventFx(dataForUpdate);
  }

  if (document.deleteDocumentId) {
    await deleteDocumentFx({ eventId: eventData.id, documentId: document.deleteDocumentId });
  }

  if (document.createDocumentId) {
    await createDocumentFx({ eventId: eventData.id, documentId: document.createDocumentId });
  }

  return { eventData: { id: eventData.id, title: eventData?.title } };
});

export const fetchEventLabelsFx = createEffect<void, IFetchEventLabelsFxResponse, AxiosError>(async () => {
  const response = await api.get(`${config.COMMON_API_URL}/admin/v1/labels`);

  return response.data;
});

export const fetchDateStatusFx = createEffect<
  void,
  { title: string; code: string; id: number }[],
  AxiosError
>(async () => {
  const response = await api.get(`${config.COMMON_API_URL}/admin/v1/date_statuses`);

  return response.data.results;
});

export const createDatesFx = createEffect<ICreateDatesFx, void, AxiosError>(async ({ id, dates }) => {
  for (const date of dates) {
    await api.post(`${route}/${id}/dates`, date);
  }
});

export const editDateFx = createEffect<IEditDateFx, void, AxiosError>(async ({ id, dates }) => {
  for (const date of dates) {
    await api.patch(`${route}/${id}/dates/${date?.id}`, date); // Дожидаемся завершения запроса
  }
});

export const editPlaceFx = createEffect<{ id: number; place_id: number }, void, AxiosError>(
  ({ id, place_id }) => {
    api.patch(`${route}/${id}`, { place_id });
  },
);

export const updateDateStepFx = createEffect<IUpdateDateStepFx, void, AxiosError>(
  async ({ datesForCreate, eventId, datesForUpdate, placeId }) => {
    await editPlaceFx({ id: eventId, place_id: placeId });

    if (!isEmpty(datesForUpdate)) {
      await editDateFx({ id: eventId, dates: datesForUpdate });
    }

    if (!isEmpty(datesForCreate)) {
      await createDatesFx({ id: eventId, dates: datesForCreate });
    }
  },
);

export const fetchEventParsers = createEffect<number | undefined, string[], AxiosError>(async (id) => {
  const response = await api.get(`${config.CRAWLER_API_URL}crawler/list/${id}`);

  return response.data;
});

export const fetchParsers = createEffect<IParserFilterParams, IEventParsers[], AxiosError>(
  async (parserFilterParams) => {
    const response = await api.get(`${config.CRAWLER_API_URL}crawler/event`, {
      params: { ...parserFilterParams },
    });

    return response.data?.data?.results;
  },
);

export const createParserDateFx = createEffect<ICreateParserDateFx, void, AxiosError>(
  async ({ parserFilterParams, duration, eventId }) => {
    const parsers = await fetchParsers(parserFilterParams);
    const dates = parsers.map((parser) => {
      const localTimezone = 'Europe/Moscow';
      const startedAt = dayjs(parser.when).tz(localTimezone).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      const finishedAt = dayjs(parser.when)
        .tz(localTimezone)
        .add(duration, 'minute')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      return {
        started_at: startedAt,
        finished_at: finishedAt,
        status_id: 1,
      };
    });

    await api.post(`${route}/${eventId}/bulk_create_dates`, dates);
  },
);

export const fetchWidgetPreviewFx = createEffect<number | string, IWidgetData, AxiosError>(
  async (hallLayoutsId) => {
    const response = await api.get(
      `${config.TICKETS_API_URL}/public/v1/hall-layouts/${hallLayoutsId}/preview`,
    );

    return response.data;
  },
);

export const fetchHallSchemeStepDataFx = createEffect(async (eventId: number) => {
  let hallData;
  let hallLayout;

  const eventData = await fetchEventFx({ id: eventId });
  const hallsData = (await fetchHallsFx({ place: String(eventData?.place?.id), limit: '150' })).results;
  if (eventData?.hall_id) {
    hallData = hallsData.find((hall) => hall.id === eventData.hall_id);

    if (hallData?.id) {
      hallLayout = await fetchHallLayoutsFx({
        hall_id: hallData?.id,
        limit: 2500,
      }).then((hallLayout) => {
        return hallLayout.results.find((hallLayoutData) => hallLayoutData?.id === eventData?.hall_layout_id);
      });
    }
  }

  if (eventData?.hall_layout_id) {
    await fetchWidgetPreviewFx(eventData.hall_layout_id);
  }

  return { eventData, hallsData, hallData, hallLayout };
});

export const editHallSchemeFx = createEffect<
  { id: number; hall_layout_id: number; hall_id: number },
  void,
  AxiosError
>(async ({ id, hall_layout_id, hall_id }) => {
  await api.patch(`${route}/${id}`, {
    hall_layout_id,
    hall_id,
  });
});

export const editPersonFx = createEffect<
  { id: number; personsId: number[]; manager_id?: number },
  void,
  AxiosError
>(async ({ id, personsId, manager_id }) => {
  await api.patch(`${route}/${id}`, {
    persons: personsId,
    manager_id,
  });
});
