import { ConfigProvider, ThemeConfig, theme as antTheme } from 'antd';
import { Locale } from 'antd/lib/locale';
import React, { PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import { GLOBAL_COLORS } from 'shared/constants/COLORS';
import Z_INDEX from 'shared/constants/Z_INDEX';

const customTheme: ThemeConfig = {
  token: {
    colorError: GLOBAL_COLORS.colorErrorActive,
  },
  components: {
    Button: {
      // primary
      primaryColor: GLOBAL_COLORS.colorText,
      colorPrimary: GLOBAL_COLORS.colorPrimary,
      colorPrimaryHover: GLOBAL_COLORS.colorPrimaryHover,
      colorPrimaryActive: GLOBAL_COLORS.colorPrimaryActive,
      primaryShadow: 'none',

      // default
      defaultColor: GLOBAL_COLORS.colorText,
      defaultBorderColor: GLOBAL_COLORS.colorBorder,
      defaultHoverBorderColor: GLOBAL_COLORS.colorBorder,
      defaultHoverColor: GLOBAL_COLORS.colorTextSecondary,
      defaultActiveColor: GLOBAL_COLORS.colorText,
      // text
      // link
      // dashed
      // danger
      colorError: GLOBAL_COLORS.colorError,
      colorErrorHover: GLOBAL_COLORS.colorErrorHover,
      colorErrorActive: GLOBAL_COLORS.colorErrorActive,
    },
    FloatButton: {
      colorPrimary: GLOBAL_COLORS.colorPrimary,
      colorPrimaryHover: GLOBAL_COLORS.colorPrimaryHover,
      colorPrimaryActive: GLOBAL_COLORS.colorPrimaryActive,
      colorPrimaryText: GLOBAL_COLORS.colorText,
    },
    Form: {
      itemMarginBottom: 20,
      verticalLabelPadding: '0 0 2px',
      labelColonMarginInlineStart: 0,
      labelRequiredMarkColor: GLOBAL_COLORS.colorErrorActive,
    },
    Table: {
      cellPaddingBlock: 12,
      cellPaddingInline: 8,
      rowSelectedBg: 'transparent',
      rowSelectedHoverBg: '#fafafa',
    },
    Tooltip: {
      zIndexPopup: Z_INDEX.ANNOUNCEMENT_EDITOR_MODAL,
    },
  },
};

export const CustomThemeProvider: React.FC<PropsWithChildren<{ locale: Locale }>> = ({
  children,
  locale,
}) => {
  const { token } = antTheme.useToken();

  const breackpoints = {
    screenXS: `${token.screenXS}px`, // 480px
    screenXSMax: `${token.screenXSMax}px`, // 575px
    screenSM: `${token.screenSM}px`, // 576px
    screenSMMax: `${token.screenSMMax}px`, // 767px
    screenMD: `${token.screenMD}px`, // 768px
    screenMDMax: `${token.screenMDMax}px`, // 991px
    screenLG: `${token.screenLG}px`, // 992px
    screenLGMax: `${token.screenLGMax}px`, // 1199px
    screenXL: `${token.screenXL}px`, // 1200px
    screenXLMax: `${token.screenXLMax}px`, // 1599px
    screenXXL: `${token.screenXXL}px`, // 1600px
  };

  return (
    <>
      <ConfigProvider theme={customTheme} locale={locale}>
        <ThemeProvider theme={{ ...breackpoints }}>{children}</ThemeProvider>
      </ConfigProvider>
    </>
  );
};
